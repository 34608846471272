import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { Box, Ram, ResponsiveContext } from '../ui';
import Layout from '../layout/primary';
import { FormIframe } from '../ui/components/ContactForm';

const BackgroundBox = styled(Box)`
  background: linear-gradient(315deg, #b40000 0%, #e63d2f 100%);
`;
const PaddingBox = styled(Box)`
  padding: ${(props) => (props.size === 'small' ? '16px' : '94px')};
`;

export const query = graphql`
  {
    prismic {
      allSupplier_registrations {
        edges {
          node {
            meta_title
            meta_description
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const content = data.prismic.allSupplier_registrations.edges[0].node;

  return (
    <Layout title={content.meta_title} description={content.meta_description}>
      <ResponsiveContext.Consumer>
        {(size) => {
          return (
            <BackgroundBox>
              <PaddingBox size={size} align="center">
                <Box width="996px" background="#FFFFFF" pad={{ vertical: 'large' }}>
                  <FormIframe hubspotFormId="11107c84-530b-4d2e-b730-d5f490963f56" />
                </Box>
              </PaddingBox>
              <Ram color="FFFFFF" />
            </BackgroundBox>
          );
        }}
      </ResponsiveContext.Consumer>
    </Layout>
  );
}
